// extracted by mini-css-extract-plugin
export var awardCard = "cR_vH";
export var awardCard__bdg_large = "cR_vM";
export var awardCard__bdg_small = "cR_vN";
export var awardCard__container = "cR_vT";
export var awardCard__description = "cR_vS";
export var awardCard__info = "cR_vP";
export var awardCard__large = "cR_vK";
export var awardCard__link = "cR_vR";
export var awardCard__post = "cR_vJ";
export var awardCard__small = "cR_vL";
export var awardCard__title = "cR_vQ";